<script lang="ts" setup>
useHead({
  title: 'Exchange Listing',
})

const navItems = [
  { label: 'Introduction', link: '/exchange-listing/' },
  { label: 'Exchange Ratings', link: '/exchange-listing/exchange-ratings' },
  { label: 'Exchange Listing Fees', link: '/exchange-listing/listing-fees' },
  { label: 'Listing Questionnaires', link: '/exchange-listing/listing-questionnaires' },
]
</script>

<template>
  <NuxtLayout name="dashboard" :contact="false">
    <template #header>
      <div class="flex gap-3 items-center">
        <div>
          <!-- Vite is doing something weird with this string literal -->
          <img
            :src="String('/icons/market-maker-monitoring.svg')"
            alt="Exchange Listing"
          >
        </div>
        <div class="text-xl font-semibold flex-shrink-0">
          Exchange Listing
        </div>
      </div>
    </template>

    <template #sidebar>
      <div class="h-full border-r border-forgd-bgd-600 w-[228px]">
        <nav
          aria-label="Exchange Listing Navigation"
          class="flex flex-col py-4 px-2 flex-shrink-0 sticky top-0 gap-1"
        >
          <UButton
            v-for="navItem in navItems"
            :key="navItem.label"
            :to="navItem.link"
            color="gray"
            variant="ghost"
            active-class="bg-forgd-bgd-400"
            class="w-full h-[34px] rounded hover:bg-forgd-bgd-400"
          >
            <span class="text-xs whitespace-nowrap text-forgd-primary-900 text-gray-600">
              {{ navItem.label }}
            </span>
          </UButton>
        </nav>
      </div>
    </template>

    <!-- main content -->
    <AppMain class="my-5">
      <slot />
    </AppMain>
  </NuxtLayout>
</template>
